import { createRouter, createWebHistory } from 'vue-router';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import store from '@/store';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "products" */ './views/ProductsView.vue'),
    },
    {
        path: '/my-orders',
        name: 'myOrders',
        component: () => import(/* webpackChunkName: "myOrders" */ './views/OrdersView.vue'),
        meta: { title: 'My Orders' },
    },
    {
        path: '/my-listings',
        name: 'listings',
        component: () => import(/* webpackChunkName: "listingsWrapper" */ './views/ListingsWrapper.vue'),
        children: [
            {
                path: '',
                name: 'myListings',
                component: () => import(/* webpackChunkName: "listingsTable" */ './views/ListingsTable.vue'),
                meta: {
                    isAdminRoute: false,
                    subtitle: 'My Listings',
                },
            },
            {
                path: 'orders',
                name: 'myListingOrders',
                component: () => import(/* webpackChunkName: "listingOrdersTable" */ './views/ListingOrdersTable.vue'),
                meta: {
                    isAdminRoute: true,
                    subtitle: 'My Listing Orders',
                },
            },
        ],
        meta: {
            isAdminRoute: true,
            title: 'My Listings',
        },
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import(/* webpackChunkName: "checkout" */ './views/CheckoutView.vue'),
        meta: { title: 'Checkout' },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ './views/ProfileView.vue'),
        meta: { title: 'Profile' },
    },
    {
        path: '/manage-orders',
        name: 'manageOrders',
        component: () => import(/* webpackChunkName: "manageOrders" */ './views/admin/orders/ManageOrders.vue'),
        meta: { title: 'Order Management' },
    },
    {
        path: '/manage-inventory',
        name: 'manageInventory',
        component: () => import(/* webpackChunkName: "manageInventory" */ './views/admin/inventory/ManageInventoryWrapper.vue'),
        children: [
            {
                path: 'products',
                name: 'productsTable',
                component: () => import(/* webpackChunkName: "productsTable" */ './views/admin/inventory/ProductsTable.vue'),
                meta: {
                    isAdminRoute: true,
                    subtitle: 'Manage Products',
                },
            },
            {
                path: 'categories',
                name: 'categoriesTable',
                component: () => import(/* webpackChunkName: "categoriesTable" */ './views/admin/inventory/CategoriesTable.vue'),
                meta: {
                    isAdminRoute: true,
                    subtitle: 'Manage Categories',
                },
            },
            {
                path: 'groups',
                name: 'groupsTable',
                component: () => import(/* webpackChunkName: "groupsTable" */ './views/admin/inventory/GroupsTable.vue'),
                meta: {
                    isAdminRoute: true,
                    subtitle: 'Manage Groups',
                },
            },
        ],
        meta: {
            isAdminRoute: true,
            title: 'Product Management',
        },
    },
    {
        path: '/manage-users',
        name: 'manageUsers',
        component: () => import(/* webpackChunkName: "manageUsers" */ './views/admin/users/ManageUsersWrapper.vue'),
        children: [
            {
                path: '',
                name: 'usersTable',
                component: () => import(/* webpackChunkName: "UsersTable" */ './views/admin/users/UsersTable.vue'),
                meta: {
                    isAdminRoute: true,
                    subtitle: 'Manage Users',
                },
            },
            {
                path: 'requests',
                name: 'requestsTable',
                component: () => import(/* webpackChunkName: "requestsTable" */ './views/admin/users/RequestsTable.vue'),
                meta: {
                    isAdminRoute: true,
                    subtitle: 'Manage Requests',
                },
            },
        ],
        meta: {
            isAdminRoute: true,
            title: 'User Management',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ './pages/Login.vue'),
        meta: {
            ignoreAuth: true,
            title: 'Login',
        },
    },
    {
        path: '/success',
        name: 'success',
        redirect: (from) => {
            store.dispatch('setAppLoadingState', true);
            if (from?.query?.redirect) {
                return { path: from.query.redirect, params: { incrementLogin: true } };
            } else {
                return { name: 'home', params: { incrementLogin: true } };
            }
        },
    },
    //Error handling routes
    {
        path: '/:catchAll(.*)',
        redirect: () => {
            return { name: 'notFound' };
        },
    },
    {
        path: '/error',
        name: 'error',
        component: () => import(/* webpackChunkName: "error" */ './pages/Error.vue'),
        meta: {
            ignoreAuth: true,
            title: 'Error',
        },
    },
    {
        path: '/not-found',
        name: 'notFound',
        component: () => import(/* webpackChunkName: "notFound" */ './pages/NotFound.vue'),
        meta: {
            ignoreAuth: true,
            title: '404',
        },
    },
    {
        path: '/access-denied',
        name: 'accessDenied',
        component: () => import(/* webpackChunkName: "access" */ './pages/AccessDenied.vue'),
        meta: {
            ignoreAuth: true,
            title: 'Access Denied',
        },
    },
    {
        path: '/access-code',
        name: 'accessCode',
        component: () => import(/* webpackChunkName: "accessCode" */ './pages/AccessCode.vue'),
        beforeEnter: async () => {
            const isAuthenticated = await AuthService.checkAuthentication();
            if (!isAuthenticated) return { name: 'login' };
        },
        meta: {
            ignoreAuth: true,
            title: 'Enter Access Code',
        },
    },
    {
        path: '/request-account',
        name: 'requestAccount',
        component: () => import(/* webpackChunkName: "requestAccount" */ './pages/RequestAccount.vue'),
        meta: {
            ignoreAuth: true,
            title: 'Request an Account',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    // Check if the route requires authentication
    if (to.meta.ignoreAuth) {
        return true;
    }

    // Check if the user is signed-in with a provider
    const isAuthenticated = await AuthService.checkAuthentication();

    // If no, go to login
    if (!isAuthenticated) {
        if (to.fullPath == '/') return { name: 'login' };
        return { name: 'login', query: { redirect: to.fullPath } };
    }

    // Check if a user record with that provider identifier exists in the DB
    await store.dispatch('fetchCurrentUser');

    // If no, go to the accessCode route
    if (!store.state.currentUser?.id) return { name: 'accessCode' };

    // Check if the route requires admin permissions, and that the user has them
    if (to.meta?.isAdminRoute && !store.state.currentUser?.isAdmin) return { name: 'accessDenied' };

    // Increment login count if parameter is present
    if (to.params?.incrementLogin) {
        try {
            await UserService.incrementLoginCount(store.state.currentUser.id);
        } catch {
            return true;
        }
    }

    // If no, go to the route
    return true;
});

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = process.env.VUE_APP_DEFAULT_TITLE + ' | ' + to.meta.title;
    } else {
        document.title = process.env.VUE_APP_DEFAULT_TITLE;
    }

    //NProgress.done();
});

export default router;
