<template>
    <div v-if="currentUser.id" class="py-3 px-5 flex align-items-center justify-content-between relative xl:static" style="min-height: 80px">
        <img src="/images/blocks/logos/hyper-light.svg" alt="Image" height="40" class="mr-2 cursor-pointer" @click="goHome" />
        <h1 class="text-white mr-0 xl:mr-6 cursor-pointer" @click="goHome">RE</h1>
        <a
            v-ripple
            class="cursor-pointer block xl:hidden text-indigo-50 p-ripple"
            v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'my-fadein',
                leaveToClass: 'hidden',
                leaveActiveClass: 'my-fadeout',
                hideOnOutsideClick: true,
            }"
        >
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="align-items-center flex-grow-1 justify-content-between hidden xl:flex absolute xl:static w-full bg-indigo-500 left-0 custom-top z-2">
            <ul class="list-none p-0 m-0 flex xl:align-items-center select-none flex-column xl:flex-row">
                <li
                    v-for="item in menuItems"
                    :key="item.to"
                    v-styleclass="{
                        selector: '@grandparent',
                        enterClass: 'hidden',
                        enterActiveClass: 'my-fadein-delayed',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'my-fadeout-delayed',
                    }"
                >
                    <router-link
                        :to="item.to"
                        v-ripple
                        class="flex px-6 p-3 xl:px-3 xl:py-2 xl:mr-1 align-items-center no-underline hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        :class="[isRouteActive(item.to) ? 'text-indigo-50 bg-indigo-600' : 'text-indigo-100']"
                    >
                        <i class="mr-2" :class="item.icon"></i>
                        <span>{{ item.label }}</span>
                    </router-link>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex xl:align-items-center select-none flex-column xl:flex-row border-top-1 border-indigo-400 xl:border-top-none">
                <li
                    v-styleclass="{
                        selector: '@grandparent',
                        enterClass: 'hidden',
                        enterActiveClass: 'my-fadein-delayed',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'my-fadeout-delayed',
                    }"
                >
                    <router-link
                        to="/checkout"
                        v-ripple
                        class="flex px-6 p-3 xl:px-3 xl:py-2 xl:ml-2 align-items-center no-underline hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        :class="[$route.path == '/checkout' ? 'text-indigo-50 bg-indigo-600' : 'text-indigo-100']"
                    >
                        <Badge :value="cart.products.length" class="mr-2 hidden xl:inline" />
                        <i class="far fa-shopping-cart fa-lg fa-fw mr-2"></i>
                        <span class="block xl:hidden font-medium">Cart</span>
                        <Badge :value="cart.products.length" class="ml-2 xl:hidden" />
                    </router-link>
                </li>
                <li
                    class="border-top-1 border-indigo-400 xl:border-top-none"
                    v-styleclass="{
                        selector: '@grandparent',
                        enterClass: 'hidden',
                        enterActiveClass: 'my-fadein-delayed',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'my-fadeout-delayed',
                    }"
                >
                    <router-link
                        to="/profile"
                        v-ripple
                        class="flex px-6 p-3 xl:px-3 xl:py-2 xl:ml-2 align-items-center no-underline hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        :class="[$route.path == '/profile' ? 'text-indigo-50 bg-indigo-600' : 'text-indigo-100']"
                    >
                        <i class="far fa-user fa-lg fa-fw mr-2 xl:mr-0"></i>
                        <div class="block xl:hidden">
                            <div class="text-indigo-50 font-medium">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
                            <span class="text-indigo-100 font-medium text-sm">{{ currentUser.preferredEmail }}</span>
                        </div>
                    </router-link>
                </li>
                <li
                    class="border-top-1 border-indigo-400 xl:border-top-none"
                    v-styleclass="{
                        selector: '@grandparent',
                        enterClass: 'hidden',
                        enterActiveClass: 'my-fadein-delayed',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'my-fadeout-delayed',
                    }"
                >
                    <a
                        href="/logout"
                        v-ripple
                        class="flex px-6 p-3 xl:px-3 xl:py-2 xl:ml-2 align-items-center no-underline hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        :class="[$route.path == '/logout' ? 'text-indigo-50 bg-indigo-600' : 'text-indigo-100']"
                    >
                        <i class="far fa-arrow-right-from-bracket fa-fw fa-lg mr-2 xl:mr-0"></i>
                        <div class="block xl:hidden">
                            <div class="text-indigo-50 font-medium">Logout</div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        menuItems() {
            if (this.currentUser.isAdmin) {
                return [
                    {
                        label: 'Home',
                        icon: 'far fa-home fa-fw fa-lg',
                        to: '/',
                    },
                    {
                        label: 'My Orders',
                        icon: 'far fa-box-circle-check fa-fw fa-lg',
                        to: '/my-orders',
                    },
                    {
                        label: 'My Listings',
                        icon: 'far fa-store fa-fw fa-lg',
                        to: '/my-listings',
                    },
                    {
                        label: 'Manage Users',
                        icon: 'far fa-users-gear fa-fw fa-lg',
                        to: '/manage-users',
                    },
                    {
                        label: 'Manage Inventory',
                        icon: 'far fa-shelves fa-fw fa-lg',
                        to: '/manage-inventory/products',
                    },
                    {
                        label: 'Manage Orders',
                        icon: 'far fa-dolly fa-fw fa-lg',
                        to: '/manage-orders',
                    },
                ];
            }

            return [
                {
                    label: 'Home',
                    icon: 'far fa-home fa-fw fa-lg',
                    to: '/',
                },
                {
                    label: 'My Orders',
                    icon: 'far fa-box-circle-check fa-fw fa-lg',
                    to: '/my-orders',
                },
                {
                    label: 'My Listings (Coming Soon)',
                    icon: 'far fa-store fa-fw fa-lg',
                    to: '/my-listings',
                },
            ];
        },
        ...mapState(['currentUser', 'cart']),
    },
    methods: {
        isRouteActive(path) {
            return this.$route.path == path;
        },
        showAlert() {
            alert('Alert message!');
        },
        goHome() {
            this.$router.push('/');
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes my-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes my-fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 1080px) {
    .my-fadein-delayed {
        animation: my-fadein 150ms linear;
    }

    .my-fadeout-delayed {
        animation: my-fadeout 150ms linear;
    }
}

.my-fadein {
    animation: my-fadein 150ms linear;
}

.my-fadeout {
    animation: my-fadeout 150ms linear;
}

.custom-top {
    top: 85%;
}
</style>
