import Axios from 'axios';

export default {
    async checkAuthentication() {
        const response = await Axios.get('/.auth/me');
        const { clientPrincipal } = response.data;
        return !!clientPrincipal?.userRoles?.includes('authenticated');
    },
    async getClientPrincipal() {
        const response = await Axios.get('/.auth/me');
        const { clientPrincipal } = response.data;
        return clientPrincipal;
    },
};
