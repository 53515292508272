import { createStore } from 'vuex';
import UserService from './services/UserService';

export default createStore({
    state: {
        appLoading: false,
        currentUser: {},
        cart: {
            products: [],
        },
    },
    getters: {},
    mutations: {
        ADD_PRODUCT_TO_CART(state, payload) {
            const existingProduct = state.cart.products.find((p) => p.id == payload.id);
            if (existingProduct) {
                existingProduct.selectedQuantity += payload.selectedQuantity;
            } else {
                state.cart.products.push(payload);
            }
        },
        CLEAR_CART(state) {
            state.cart.products = [];
        },
        UPDATE_PRODUCT_QUANTITY_IN_CART(state, payload) {
            const existingProduct = state.cart.products.find((p) => p.id == payload.id);

            if (existingProduct) {
                existingProduct.selectedQuantity = payload.selectedQuantity;
            }
        },
        REMOVE_PRODUCT_FROM_CART(state, productId) {
            state.cart.products = state.cart.products.filter((p) => p.id != productId);
        },
        REMOVE_UNAVAILABLE_PRODUCTS_FROM_CART(state, conflictingProducts) {
            state.cart.products = state.cart.products.filter((p) => {
                return !conflictingProducts.some((c) => c.id == p.id);
            });
        },
        SET_CURRENT_USER(state, user) {
            state.currentUser = {
                ...user,
            };
        },
        SET_APP_LOADING_STATE(state, isLoading) {
            state.appLoading = isLoading;
        },
        SET_DEFAULT_STATE(state) {
            state.appLoading = false;
            state.currentUser = {};
            state.cart = {
                products: [],
            };
        },
    },
    actions: {
        async fetchCurrentUser({ commit, state }, forceUpdate) {
            const existingUser = state.currentUser;
            if (existingUser.id && !forceUpdate) {
                commit('SET_CURRENT_USER', existingUser);
            } else {
                try {
                    const user = await UserService.getSelf();
                    commit('SET_CURRENT_USER', user);
                } catch (error) {
                    commit('SET_CURRENT_USER', {});
                }
            }
        },
        async setCurrentUser({ commit, state }, payload) {
            try {
                const updatedUser = await UserService.updateUser(payload.userId, payload);
                commit('SET_CURRENT_USER', updatedUser);
            } catch (error) {
                commit('SET_CURRENT_USER', state.user);
            }
        },
        addProductToCart({ commit }, payload) {
            commit('ADD_PRODUCT_TO_CART', payload);
        },
        clearCart({ commit }) {
            commit('CLEAR_CART');
        },
        removeProductFromCart({ commit }, productId) {
            commit('REMOVE_PRODUCT_FROM_CART', productId);
        },
        removeUnavailableProductsFromCart({ commit }, conflictingProducts) {
            commit('REMOVE_UNAVAILABLE_PRODUCTS_FROM_CART', conflictingProducts);
        },
        setAppLoadingState({ commit }, isLoading) {
            commit('SET_APP_LOADING_STATE', isLoading);
        },
        setDefaultState({ commit }) {
            commit('SET_DEFAULT_STATE');
        },
    },
    modules: {},
});
