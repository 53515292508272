import Axios from 'axios';

export default {
    async createUser(payload) {
        try {
            const response = await Axios.post(`/api/users`, payload);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error creating user: ${error.message}`,
            };
        }
    },
    async getSelf() {
        try {
            const response = await Axios.get(`/api/users/me`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error retrieving self: ${error.message}`,
            };
        }
    },
    async getUsers() {
        try {
            const response = await Axios.get(`/api/users`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error retrieving users: ${error.message}`,
            };
        }
    },
    async getUserByProviderIdentifier(providerIdentifier) {
        try {
            const response = await Axios.get(`/api/users/auth-provider/${providerIdentifier}`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error retrieving user: ${error.message}`,
            };
        }
    },
    async updateUser(userId, payload) {
        try {
            const response = await Axios.patch(`/api/users/${userId}`, payload);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error updating user: ${error.message}`,
            };
        }
    },
    async deleteUser(userId) {
        try {
            const response = await Axios.delete(`/api/users/${userId}`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error deleting user: ${error.message}`,
            };
        }
    },
    async activateAccount(payload) {
        try {
            const response = await Axios.patch(`/api/users/activate`, payload);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error activating user account: ${error.message}`,
            };
        }
    },
    async incrementLoginCount(userId) {
        try {
            const response = await Axios.post(`/api/users/${userId}/increment-login`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error incrementing user login count: ${error.message}`,
            };
        }
    },
};
