<template>
    <div class="min-h-screen flex flex-column">
        <Toast position="top-center" />
        <div class="bg-indigo-500" style="height: 150px">
            <AppTopbar />
        </div>
        <div>
            <router-view v-slot="{ Component }">
                <transition name="slide" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import AppTopbar from '@/components/layout/AppTopbar.vue';
import { dom } from '@fortawesome/fontawesome-svg-core';

dom.watch();

export default {
    components: {
        AppTopbar,
    },
};
</script>

<style lang="scss">
@import './assets/styles/app/layout.scss';

a,
button {
    mask-image: radial-gradient(white, black);
    -webkit-mask-image: radial-gradient(white, black);
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.slide-enter-from {
    opacity: 0;
    transform: translateY(100px);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.5s ease-out;
}

.slide-leave-to {
    opacity: 0;
    transform: translateY(100px);
}
</style>
