<template>
    <Loading v-if="appLoading" />
    <Error v-if="$route.path === '/error'" />
    <AccessDenied v-else-if="$route.path === '/access-denied'" />
    <NotFound v-else-if="$route.path === '/not-found'" />
    <Login v-else-if="$route.path === '/login'" />
    <AccessCode v-else-if="$route.path === '/access-code'" />
    <RequestAccount v-else-if="$route.path === '/request-account'" />
    <App v-else />
</template>

<script>
import { mapState } from 'vuex';

import AccessDenied from '@/pages/AccessDenied.vue';
import AccessCode from '@/pages/AccessCode.vue';
import App from '@/App.vue';
import Error from '@/pages/Error.vue';
import Loading from '@/pages/Loading.vue';
import Login from '@/pages/Login.vue';
import NotFound from '@/pages/NotFound.vue';
import RequestAccount from '@/pages/RequestAccount.vue';

export default {
    name: 'AppWrapper',
    components: {
        AccessDenied,
        AccessCode,
        App,
        Error,
        Loading,
        Login,
        NotFound,
        RequestAccount,
    },
    computed: {
        ...mapState(['appLoading']),
    },
};
</script>
