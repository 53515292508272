<template>
    <div class="invite-wrapper">
        <div class="mb-2 mx-4 w-11 lg:w-4">
            <div class="flex flex-column justify-content-center w-full h-auto border-round shadow-4 p-6 surface-card">
                <div class="text-center">
                    <img src="/images/blocks/logos/hyper.svg" alt="Image" height="80" class="mr-2 cursor-pointer" @click="goHome" />
                    <h2>Request an Account</h2>
                </div>
                <div class="formgrid grid mb-3">
                    <div class="field col-12 lg:col-6">
                        <label for="firstName">First Name</label>
                        <InputText id="firstName" v-model="firstName" class="inputfield w-full" />
                    </div>
                    <div class="field col-12 lg:col-6">
                        <label for="lastName">Last Name</label>
                        <InputText id="lastName" v-model="lastName" class="inputfield w-full" />
                    </div>
                    <div class="field col-12">
                        <label for="phone">Phone Number</label>
                        <InputMask
                            id="phone"
                            name="phone"
                            v-model="phoneNumber"
                            type="tel"
                            autocomplete="tel"
                            aria-autocomplete="on"
                            mask="(999) 999-9999"
                            placeholder="ex. (999) 999-9999"
                            class="inputfield w-full"
                        />
                    </div>
                </div>
                <div>
                    <Button label="Submit" class="w-full" :loading="loading" @click="submitAccountRequest" :disabled="!firstName || !lastName || !phoneNumber" />
                </div>
            </div>
        </div>
        <div class="flex flex-column lg:flex-row lg:justify-content-between align-items-center">
            <h3 class="lg:mr-6">Rack &apos;Em Up Garage</h3>
            <span>Copyright Ⓒ 2022 Aaron Roach</span>
        </div>
    </div>
</template>

<script>
import UserAccountRequestService from '@/services/UserAccountRequestService';

export default {
    name: 'RequestAccount',
    data() {
        return {
            loading: false,
            firstName: null,
            lastName: null,
            phoneNumber: null,
        };
    },
    methods: {
        async submitAccountRequest() {
            this.loading = true;
            try {
                const payload = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phoneNumber: this.phoneNumber,
                };

                await UserAccountRequestService.createUserAccountRequest(payload);

                this.loading = false;

                await this.$swal({
                    icon: 'success',
                    title: 'Request Submitted!',
                    text: 'Returning to login...',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                });

                this.$router.push({ name: 'login' });
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Request Failed',
                    text: 'An error occurred while processing your request, please try again',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#d33',
                });

                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.invite-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.continue-enter-from {
    opacity: 0;
    transform: translateX(25px);
}

.continue-enter-active,
.continue-leave-active {
    transition: all 0.3s ease-out;
}

.continue-leave-to {
    opacity: 0;
    transform: translateX(-25px);
}
</style>
