import Axios from 'axios';

export default {
    async createUserAccountRequest(payload) {
        try {
            const response = await Axios.post(`/api/account-requests`, payload);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error creating account request: ${error.message}`,
            };
        }
    },
    async getUserAccountRequests() {
        try {
            const response = await Axios.get(`/api/account-requests`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error creating account request: ${error.message}`,
            };
        }
    },
    async approveUserAccountRequest(requestId) {
        try {
            const response = await Axios.put(`/api/account-requests/${requestId}/approve`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error approving account request: ${error.message}`,
            };
        }
    },
    async denyUserAccountRequest(requestId) {
        try {
            const response = await Axios.delete(`/api/account-requests/${requestId}`);
            return response.data;
        } catch (error) {
            throw {
                status: error.response.status,
                message: `Error denying account request: ${error.message}`,
            };
        }
    },
};
