<template>
    <div class="invite-wrapper text-center">
        <div class="mb-2 mx-4 w-11 lg:w-3">
            <div class="flex flex-column justify-content-center w-full h-auto border-round shadow-4 p-6 text-center surface-card">
                <img src="/images/blocks/logos/hyper.svg" alt="Image" height="80" class="mr-2 cursor-pointer" @click="goHome" />
                <Transition name="continue" mode="out-in">
                    <div v-if="!phoneNumberSubmitted">
                        <h2>Enter Your Phone Number</h2>
                        <div>
                            <InputMask
                                id="phone"
                                name="phone"
                                v-model="phoneNumber"
                                type="tel"
                                autocomplete="tel"
                                aria-autocomplete="on"
                                mask="(999) 999-9999"
                                placeholder="ex. (999) 999-9999"
                                class="w-full mb-4"
                            />
                        </div>
                        <div>
                            <Button label="Continue" class="w-full" :loading="loading" @click="phoneNumberSubmitted = true" :disabled="!phoneNumber" />
                        </div>
                    </div>
                    <div v-else>
                        <h2>Enter Your Access Code</h2>
                        <div>
                            <InputText v-model="accessCode" class="w-full mb-4" placeholder="Enter Access Code" />
                        </div>
                        <div>
                            <Button label="Submit" class="w-full" :loading="loading" @click="activateAccount" :disabled="!accessCode" />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
        <div class="flex flex-column lg:flex-row lg:justify-content-between align-items-center">
            <h3 class="lg:mr-6">Rack &apos;Em Up Exchange</h3>
            <span>Copyright Ⓒ 2022 Aaron Roach</span>
        </div>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

export default {
    name: 'AccessCode',
    data() {
        return {
            loading: false,
            phoneNumber: null,
            phoneNumberSubmitted: false,
            accessCode: null,
        };
    },
    methods: {
        async activateAccount() {
            this.loading = true;

            const clientPrincipal = await AuthService.getClientPrincipal();

            if (!clientPrincipal || !clientPrincipal.identityProvider) {
                this.$swal({
                    icon: 'error',
                    title: 'Verification Failed',
                    text: 'Invalid access code',
                    confirmText: 'Try Again',
                });
            }

            const payload = {
                phoneNumber: this.phoneNumber,
                accessCode: this.accessCode,
                azureADIdentifier: clientPrincipal.identityProvider === 'aad' ? clientPrincipal.userDetails : null,
                googleIdentifier: clientPrincipal.identityProvider === 'google' ? clientPrincipal.userDetails : null,
            };

            const isAccountActivated = await UserService.activateAccount(payload);

            if (!isAccountActivated) {
                this.$swal({
                    icon: 'error',
                    title: 'Verification Failed',
                    text: 'Invalid access code',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#d33',
                });
            } else {
                await this.$store.dispatch('fetchCurrentUser', true);

                await this.$swal({
                    icon: 'success',
                    title: 'Account Verified!',
                    text: 'You will be taken to the homepage momentarily',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    allowOutsideClick: false,
                });

                this.$router.push({ name: 'home' });
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.invite-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.continue-enter-from {
    opacity: 0;
    transform: translateX(25px);
}

.continue-enter-active,
.continue-leave-active {
    transition: all 0.3s ease-out;
}

.continue-leave-to {
    opacity: 0;
    transform: translateX(-25px);
}
</style>
