<template>
    <div class="min-h-screen flex flex-column justify-content-center surface-0">
        <div class="flex">
            <div class="w-12 sm:w-6 px-4 py-8 md:px-6 lg:px-8">
                <div class="border-left-2 border-indigo-500">
                    <span class="bg-white text-indigo-500 font-bold text-2xl inline-block px-3">403</span>
                </div>
                <div class="mt-6 mb-5 font-bold text-6xl text-900">Access Denied</div>
                <p class="text-700 text-3xl mt-0 mb-6">You are not authorized to view this resource.</p>
                <div>
                    <Button class="p-button-text mr-2" label="Go Back" icon="far fa-arrow-left fa-fw fa-lg" @click="goBack"></Button>
                    <Button label="Go Home" icon="far fa-home fa-fw fa-lg" @click="goHome"></Button>
                </div>
            </div>
            <img class="w-6 hidden sm:block p-8" src="images/status/access-denied.svg" />
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goHome() {
            this.$router.push('/');
        },
        goBack() {
            this.$router.back();
        },
    },
};
</script>
