<template>
    <div class="login-wrapper text-center">
        <div class="mb-7 mx-4">
            <div class="flex flex-column justify-content-center lg:w-25 h-auto border-round shadow-4 p-6 mb-6 text-center surface-card">
                <img src="/images/blocks/logos/hyper.svg" alt="Image" height="80" class="mr-2 cursor-pointer" @click="goHome" />
                <h1>Welcome to RG</h1>
                <div>
                    <Button icon="fab fa-microsoft fa-fw fa-2x" label="Login with Microsoft" class="w-16rem mb-4" @click="routeToAzureADLogin"></Button>
                </div>
                <div><Button icon="fab fa-google fa-fw fa-2x" label="Login with Google" class="w-16rem mb-4" @click="routeToGoogleLogin" /></div>
            </div>
            <div class="w-25 h-auto border-round shadow-4 p-6 text-center surface-card">
                <small>
                    Haven't received an invite?
                    <router-link to="/request-account" class="no-underline">Click here to request an account</router-link>
                </small>
            </div>
        </div>
        <div class="flex flex-column lg:flex-row lg:justify-content-between align-items-center">
            <h3 class="lg:mr-6">Rack &apos;Em Up Garage</h3>
            <span>Copyright Ⓒ 2022 Aaron Roach</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            aadButtonLoading: false,
            googleButtonLoading: false,
        };
    },
    mounted() {
        this.$store.dispatch('setDefaultState');
    },
    methods: {
        routeToAzureADLogin() {
            if (this.$route.query.redirect) {
                window.location.href = `/.auth/login/aad?post_login_redirect_uri=${process.env.VUE_APP_ROOT_URL}/success?redirect=${this.$route.query.redirect}`;
            } else {
                window.location.href = `/.auth/login/aad?post_login_redirect_uri=${process.env.VUE_APP_ROOT_URL}/success`;
            }
        },
        routeToGoogleLogin() {
            if (this.$route.query.redirect) {
                window.location.href = `/.auth/login/google?post_login_redirect_uri=${process.env.VUE_APP_ROOT_URL}/success?redirect=${this.$route.query.redirect}`;
            } else {
                window.location.href = `/.auth/login/google?post_login_redirect_uri=${process.env.VUE_APP_ROOT_URL}/success`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}
</style>
